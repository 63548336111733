var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.url)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return _vm.showPopup = false; }),expression:"() => showPopup = false"}],staticClass:"company-video-card",class:[
    'company-video-card',
    {'company-video-card-lg': _vm.isDesktop},
    {'company-video-card-md': _vm.isTablet},
    {'company-video-card-sm': _vm.isMobile} ]},[_c('div',{staticClass:"h-100 d-flex flex-column row-gap-4 overflow-hidden cursor-pointer"},[_c('div',{class:[
        'poster d-flex justify-content-center align-items-center rounded position-relative',
        {'thumbnail': _vm.thumbnail},
        {'thumbnail-lg': _vm.isDesktop},
        {'thumbnail-md': _vm.isTablet},
        {'thumbnail-sm': _vm.isMobile} ],on:{"click":function($event){_vm.showPopup = true}}},[(_vm.thumbnail)?_c('img',{staticClass:"thumbnail-img rounded",attrs:{"src":_vm.thumbnail,"alt":"thumbnail"}}):_vm._e(),_c('div',{staticClass:"position-absolute w-100 h-100 d-flex align-items-center justify-content-center"},[_c('font-awesome-component',{attrs:{"container-classes":"icon-48 play-icon","icon":"far fa-play-circle","icon-classes":"text-white-white-100 icon-48"}})],1)]),_c('video',{ref:"player"}),(!_vm.hideInformation)?_c('div',{class:[
        'px-3 pb-3 d-flex flex-column justify-content-between',
        {'content-lg': _vm.isDesktop},
        {'content-md': _vm.isTablet},
        {'content-sm': _vm.isMobile} ],on:{"click":function($event){_vm.showPopup = true}}},[_c('div',{staticClass:"body d-flex"},[_c('h6',{class:[
            'name m-0 font-weight-bold text-left text-neutral-n-8-dark-ink',
            {'ellipsis-2': _vm.isTablet},
            {'ellipsis-3': !_vm.isTablet}
          ]},[_vm._v(" "+_vm._s(_vm.name)+" ")])]),(_vm.exhibitor)?_c('div',{staticClass:"d-flex align-items-center column-gap-1"},[_c('avatar-solo-widget',{attrs:{"src":_vm.FileResourceHelper.getFullPath(
            _vm.exhibitor && _vm.exhibitor.logoFileResource ? _vm.exhibitor.logoFileResource : undefined,
            'w40'
          ),"with-borders":true,"default-icon":"fa-regular fa-buildings","icon-classes":"text-neutral-n-6-label icon-8","icon-container-classes":"icon-12","size":"16"}}),(_vm.exhibitor && _vm.exhibitor.name)?_c('label',{staticClass:"label-3 medium text-left text-neutral-n-6-label m-0 ellipsis-1"},[_vm._v(" "+_vm._s(_vm.exhibitor.name)+" ")]):_vm._e()],1):_vm._e()]):_vm._e()]),(_vm.showPopup)?_c('div',{staticClass:"backdrop",on:{"click":function($event){_vm.showPopup = false}}}):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }