





























































































import { Component, Prop, Watch } from 'vue-property-decorator';
import FileResourceHelper from '@utils/helpers/FileResourceHelper';
import AvatarSoloWidget from '@/components/AvatarSoloWidget.vue';
import Exhibitor from '@/models/graphql/Exhibitor';
import urlParser from 'js-video-url-parser';
import 'plyr/dist/plyr.css';
import Plyr from 'plyr';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import UploadFileHandler from '@/utils/UploadFileHandler';
import EntityType from '@/utils/enums/EntityType';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import { Getter } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import Community from '@/models/graphql/Community';
import FileResource from '@/models/graphql/FileResource';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

type SourceType = { provider: 'youtube' | 'vimeo' | undefined; src: string; type?: string} | null;

@Component({
  components: {
    FontAwesomeComponent,
    AvatarSoloWidget,
  },
  inheritAttrs: false,
})
export default class CompanyVideoCard extends BreakpointWrapper {
  @Prop({ required: true, default: '' })
  readonly uid!: string;

  @Prop({ required: false, default: 0 })
  readonly id!: number;

  @Prop({ required: true, default: '' })
  readonly name!: string;

  @Prop({ required: true, default: null })
  readonly url!: string;

  @Prop({ required: true, default: null })
  readonly exhibitor!: Exhibitor;

  @Prop({ required: false, default: false })
  readonly hideInformation!: boolean;

  @Prop({ required: true, default: null })
  readonly thumbnailFileResource!: FileResource;

  @Getter
  authUser!: CommunityUser;

  @Getter
  community!: Community;

  private FileResourceHelper = FileResourceHelper;

  private player: Plyr | null = null;

  private showPopup = false;

  get source(): SourceType {
    const info = urlParser.parse(this.url || '') as { provider: string; id: string };
    if (info && info.provider && ['youtube', 'vimeo'].includes(info.provider)) {
      return { provider: info.provider, src: info.id } as SourceType;
    }
    return {
      src: this.url,
      type: `video/${UploadFileHandler.getMime(this.url)}`,
    } as SourceType;
  }

  private get thumbnail(): string {
    if (this.thumbnailFileResource) {
      return FileResourceHelper.getFullPath(this.thumbnailFileResource);
    }
    return '';
  }

  mounted(): void {
    this.$nextTick(() => {
      this.player = new Plyr(this.$refs.player as HTMLElement, {
        ratio: '16:9',
        hideControls: true,
        resetOnEnd: true,
      });
      if (this.source) {
        this.player.source = {
          type: 'video',
          sources: [this.source],
        };
      }
      const videoElement = this.$el.querySelector('.plyr--video');
      if (videoElement) {
        // eslint-disable-next-line no-unused-expressions
        this.player.elements.container?.classList.add('popup-video', 'd-none');
        this.player.on('ready', () => {
          if (this.player) {
            this.player.muted = true;
          }
        });
      }
    });
  }

  beforeDestroy(): void {
    if (this.player) {
      this.player.destroy();
    }
  }

  @Watch('showPopup')
  private togglePopup(): void {
    if (this.player
      && this.player.elements
      && this.player.elements.container
      && this.source
    ) {
      if (this.showPopup) {
        this.player.elements.container.classList.remove('d-none');
        this.$logger.logMatomoStats(
          this.authUser,
          this.community.code as string,
          EntityType.EXHIBITOR_VIDEO,
          StatLoggerActions.VIEW,
          'viewExhibitorVideo',
          this.id,
          this.uid,
          StatLoggerCategories.EXHIBITOR_VIDEO,
          this.$i18n.locale,
        );
      } else {
        this.player.pause();
        this.player.elements.container.classList.add('d-none');
      }
    }
  }
}
